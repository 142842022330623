import { useHistory } from 'react-router';
import { LocationState } from 'history';

import useWithBaseUrl from './useWithBaseUrl';
import { useCallback } from 'react';

function useRedirectTo<HistoryLocationState = LocationState>(path: string, state?: HistoryLocationState) {
  const history = useHistory();
  const fullPath = useWithBaseUrl(path);

  return useCallback(() => history.push(fullPath, state), [history, fullPath, state]);
}

export default useRedirectTo;
