import { Order, ReturnRequest, ReturnCarrierService, OverviewCompletionState } from '../types';
import { useSettings } from './useSettings';
import { isConflictError } from '../apiHelpers';
import { isReturnPeriodExpired } from '../orderHelpers';
import { useReturnPortalCountry } from './useReturnPortalCountry';
import { SubmitState } from './useReturnRequestActions';
import useReturnPortalType from './useReturnPortalType';

type StateType =
  | 'empty'
  | 'create'
  | 'update'
  | 'next'
  | 'create-without-items'
  | 'next-without-items'
  | 'carrier-selection'
  | 'no-eligible-items'
  | 'expired'
  | 'return-not-supported'
  | 'return-already-created'
  | 'order-not-delivered'
  | 'order-country-mismatch'
  | 'confirmation-redirect'
  | 'external-redirect'
  | 'lite';

export type Params = {
  order?: Order;
  returnRequest?: ReturnRequest;
  submitState: SubmitState;
  overviewCompletionState: OverviewCompletionState;
  returnCarrierServices?: ReturnCarrierService[];
  isSubsequentReturnInitiated?: boolean;
};

// TODO: we need to do something with this awful gordian knot of conditions >.<
function useOverviewState(params: Params): StateType {
  const {
    order,
    returnRequest,
    submitState,
    overviewCompletionState,
    returnCarrierServices = [],
    isSubsequentReturnInitiated,
  } = params;
  const { submittedReturnRequest, error: submitError } = submitState;

  const settings = useSettings();
  const returnPortalCountry = useReturnPortalCountry();
  const { isLite } = useReturnPortalType();

  if (overviewCompletionState.type === 'completed' && !submittedReturnRequest) {
    return 'carrier-selection';
  }

  if (!order || (order.returnRequestIds.length > 0 && !returnRequest)) {
    return 'empty';
  }

  if (order.items.length > 0 && order.items.every(item => !item.isReturnable || item.returnRequestId)) {
    const isAllItemsNonReturnable = order.items.every(item => !item.isReturnable);

    // all items returned, it's not subsequent flow -> we display confirmation screen instead of this one
    if (isAllItemsNonReturnable || isSubsequentReturnInitiated) {
      return 'no-eligible-items';
    }
  }

  if (order.isReturnNotSupported) {
    return 'return-not-supported';
  }

  if (returnPortalCountry?.toLowerCase() !== order.countryCode?.toLowerCase()) {
    return 'order-country-mismatch';
  }

  if (isConflictError(submitError)) {
    return 'return-already-created';
  }

  if (submittedReturnRequest) {
    return submittedReturnRequest?.workflowType === 'external' ? 'external-redirect' : 'confirmation-redirect';
  }

  if (returnRequest?.workflowType === 'external') {
    return order.hasReturnShipment ? 'confirmation-redirect' : 'update';
  }

  if (order.returnRequestIds.length > 0 && !isSubsequentReturnInitiated) {
    return 'confirmation-redirect';
  }

  if (isReturnPeriodExpired(order, settings.returnPeriod)) {
    return 'expired';
  }

  if (returnCarrierServices.length < 2) {
    return isLite ? 'create-without-items' : 'create';
  }

  if (settings.isExternalRedirectEnabled) {
    return 'create';
  }

  return isLite ? 'next-without-items' : 'next';
}

export default useOverviewState;
