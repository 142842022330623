import { GroupedReturnItem, ItemToSubmit, OrderItem, OverviewReturnItem, ReturnItem } from './types';

export type GroupableItem = {
  productKey: string;
  isReturnable?: boolean; // return request items don't have isReturnable (we imply they're returnable)
  returnRequestId: number | null;
};

function isSameGroup(a: GroupableItem, b: GroupableItem): boolean {
  return a.productKey === b.productKey && a.isReturnable === b.isReturnable && a.returnRequestId === b.returnRequestId;
}

export function getGroupedItems<T extends GroupableItem>(items: T[]): GroupedReturnItem<T>[] {
  return items.reduce<GroupedReturnItem<T>[]>((acc, item) => {
    const itemInAcc = acc.find(i => isSameGroup(i, item));

    if (itemInAcc) {
      itemInAcc.totalQuantity++;
    } else {
      acc.push({ ...item, totalQuantity: 1 });
    }

    return acc;
  }, []);
}

export function getUngroupedItems(orderItems: OrderItem[], selectedItems: OverviewReturnItem[]): ItemToSubmit[] {
  const items = [];
  const returnItems = transformToReturnItems(orderItems);

  for (let i = 0; i < selectedItems.length; i++) {
    const ungroupedItems = returnItems
      .filter(item => isSameGroup(item, selectedItems[i]))
      .slice(0, selectedItems[i].quantity)
      .map(({ productKey, ...item }) => ({ ...item, reason: selectedItems[i].reason || '' }));

    items.push(...ungroupedItems);
  }

  return items;
}

export function transformToReturnItems(items: OrderItem[]): ReturnItem[] {
  return items.map(({ id, ...item }) => ({ ...item, orderItemId: id }));
}
