import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';

import Link from '../common/Link';
import { ReturnRequest, ReturnState } from '../../types';

const Container = styled('div')`
  width: 100%;
  font-size: 16px;
  margin: 32px 0;
  line-height: 24px;
`;

type Props = {
  color: string;
  returnState?: ReturnState;
  returnRequest: ReturnRequest;
  onLinkClick?: () => void;
};

function CreateSubsequentReturn(props: Props) {
  const { returnState, returnRequest } = props;
  const shouldDisplay = returnState === 'exists' && returnRequest.returnsLeft > 0;

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <Trans i18nKey="confirmation_additionalReturnText">
        <Link color={props.color} onClick={props.onLinkClick} />
      </Trans>
    </Container>
  );
}

export default React.memo(CreateSubsequentReturn);
