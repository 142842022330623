import styled from '@emotion/styled';

const ProductItemContainer = styled('div')`
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export default ProductItemContainer;
