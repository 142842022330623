import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ReturnPortalTranslations } from './types';

export function initI18next(translations: ReturnPortalTranslations = {}) {
  const resources = makeResourcesFromTranslations('common', translations);

  return i18next.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    saveMissing: false,
    ns: ['common', 'carrier-services'],
    defaultNS: 'common',
    nsSeparator: false,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
  });
}

const carrierServicesTranslations: ReturnPortalTranslations = {
  en: {
    '6d165035-c468-4424-bc6a-f5fc528857af': 'Printable return label',
    'ee2bfb91-8238-44f6-bb1a-7dd26b1fd72b': 'Printable return label',
    'eba39b6d-a43c-4ff7-b7fa-cf9b5b291b0f': 'Printerless Return',
  },
  nl: {
    '6d165035-c468-4424-bc6a-f5fc528857af': 'Retourlabel printen',
    'ee2bfb91-8238-44f6-bb1a-7dd26b1fd72b': 'Retourlabel printen',
    'eba39b6d-a43c-4ff7-b7fa-cf9b5b291b0f': 'Digitaal retourlabel',
  },
};

export function makeResourcesFromTranslations(namespace: string, translations: ReturnPortalTranslations): Resource {
  const resource: Resource = {};

  const languages = Object.keys(translations);

  languages.forEach(lang => {
    resource[lang] = { [namespace]: translations[lang] };
  });

  // it's easier to have hard-coded carrier services translations in a separated namespace
  // it helps to avoid them being overwritten when we load new translations when language changes
  Object.entries(carrierServicesTranslations).forEach(([lang, translations]) => {
    resource[lang] = { ...resource[lang], 'carrier-services': translations };
  });

  return resource;
}
