import React, { useCallback, useMemo, useRef } from 'react';

import SelectableProductsList, { SelectableProductsListRefValue } from './SelectableProductsList';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import OrderInfo from './OrderInfo';
import ItemsCountWithAction from './ItemsCountWithAction';
import NonReturnableProducts from './NonReturnableProducts';
import { isDesktop } from '../../sizeHelpers';
import useReturnItems from '../../hooks/useReturnItems';
import { ErrorsByProductKey, ItemToSubmit, Order, OverviewReturnItem, ReturnRequest } from '../../types';
import ViewHeading from '../page-layout/ViewHeading';
import LayoutWithAction from '../LayoutWithAction';

import { getUngroupedItems } from '../../itemGroupingHelpers';
import { validateItems } from './itemsValidator';

type Props = {
  order?: Order;
  returnRequest?: ReturnRequest;
  buttonText?: string;
  buttonName?: string;
  isButtonLoading?: boolean;
  hasButtonError?: boolean;
  onButtonClick: (items: ItemToSubmit[]) => void;
};

function ProductsSelection(props: Props) {
  const {
    order,
    returnRequest,
    buttonText = 'createReturnButton',
    buttonName = 'createReturn',
    isButtonLoading,
    hasButtonError,
    onButtonClick,
  } = props;
  const orderItems = order?.items || [];
  const returnRequestItems = returnRequest?.items || [];
  const productsListRef = useRef<SelectableProductsListRefValue>(null);

  const { returnItems, selectItem, changeItemReason, setItemsErrors, changeItemQuantity } = useReturnItems(
    orderItems,
    returnRequestItems
  );
  const items = useMemo(() => returnItems.filter(item => isItemReturnableOrUpdatable(item, returnRequest)), [
    returnItems,
    returnRequest,
  ]);
  const selectedItems = useMemo(() => items.filter(item => item.selected), [items]);
  const nonReturnableItems = useMemo(
    () => returnItems.filter(item => !isItemReturnableOrUpdatable(item, returnRequest)),
    [returnItems, returnRequest]
  );

  const size = useReturnPortalSize();

  const handleButtonClick = useCallback(() => {
    const errors = validateItems(selectedItems);
    const itemProductKeyWithError = findFirstItemProductKeyWithError(errors);

    if (itemProductKeyWithError) {
      setItemsErrors(errors);
      productsListRef.current?.scrollToItem(itemProductKeyWithError);
    } else {
      const itemsToSubmit = getUngroupedItems(orderItems, selectedItems);

      onButtonClick(itemsToSubmit);
    }
  }, [onButtonClick, selectedItems, orderItems, setItemsErrors]);

  const headingElement = <ViewHeading>overview_heading</ViewHeading>;
  const infoElement = <OrderInfo order={order} orientation={isDesktop(size) ? 'row' : 'column'} />;

  const contentElement = (
    <>
      <SelectableProductsList
        ref={productsListRef}
        items={items}
        onItemCheck={selectItem}
        onItemReasonChange={changeItemReason}
        onQuantityChange={changeItemQuantity}
      />
      <NonReturnableProducts items={nonReturnableItems} />
    </>
  );

  const itemsCountElement = (
    <ItemsCountWithAction
      selectedItems={selectedItems}
      onButtonClick={handleButtonClick}
      isLoading={isButtonLoading}
      hasError={hasButtonError}
      isDesktop={isDesktop(size)}
      buttonText={buttonText}
      buttonName={buttonName}
    />
  );

  return (
    <LayoutWithAction heading={headingElement} info={infoElement} content={contentElement} button={itemsCountElement} />
  );
}

function findFirstItemProductKeyWithError(errors: ErrorsByProductKey): string | undefined {
  const [productKey] = Object.entries(errors).find(([_, error]) => error.quantity || error.reason) || [];
  return productKey;
}

function isItemReturnableOrUpdatable(item: OverviewReturnItem, returnRequest?: ReturnRequest) {
  if (!item.isReturnable) {
    return false;
  }

  // In case of external return we allow to edit already submitted items
  if (returnRequest?.workflowType === 'external') {
    return true;
  }

  return !item.returnRequestId;
}

export default React.memo(ProductsSelection);
