import React from 'react';

import ProductImage from './ProductImage';
import ProductInfo from './ProductInfo';
import { ProductListItem } from '../../types';
import ProductItemContainer from './ProductItemContainer';

export type ProductItemProps = ProductListItem & { isQuantityHidden?: boolean };

function ProductItem(props: ProductItemProps) {
  const { productImageUrl, name, size, price, currency, totalQuantity, isQuantityHidden } = props;

  return (
    <ProductItemContainer data-testid="product-item">
      <ProductImage url={productImageUrl} />
      <ProductInfo
        name={name}
        size={size}
        price={price}
        currency={currency}
        quantity={totalQuantity}
        isQuantityHidden={isQuantityHidden}
      />
    </ProductItemContainer>
  );
}

export default React.memo(ProductItem);
