import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useWithBaseUrl from '../../hooks/useWithBaseUrl';
import useShopId from '../../hooks/useShopId';
import useAuth from '../../hooks/useAuth';

function DeepLinkLogin() {
  const overviewUrl = useWithBaseUrl('/return-overview');
  const loginUrl = useWithBaseUrl('/login');
  const shopId = useShopId();
  const { login } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const params = parseQueryString(location.search);

  useEffect(() => {
    if (!params) {
      history.push(loginUrl);
    } else {
      const { orderId, email, returnRequestId } = params;

      login({ orderId, email, shopId }).then((success: boolean) => {
        if (success) {
          history.push(overviewUrl, { returnRequestId });
        } else {
          history.push(loginUrl);
        }
      });
    }
  }, [params, history, login, loginUrl, overviewUrl, shopId]);

  return <div>Logging in...</div>;
}

type DeepLinkParams = {
  orderId: string;
  email: string;
  returnRequestId?: number;
};

function parseQueryString(query: string): DeepLinkParams | null {
  const searchParams = new URLSearchParams(query);

  const orderId = searchParams.get('order');
  const email = searchParams.get('email');
  const returnRequestId = parseInt(searchParams.get('returnRequestId') || '');

  if (!orderId || !email) {
    return null;
  }

  return {
    orderId,
    email,
    returnRequestId: returnRequestId || undefined,
  };
}

export default React.memo(DeepLinkLogin);
