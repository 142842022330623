import React, { useCallback, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Text from '../common/Text';
import colors from '../common/colors';

type StyleProps = {
  color: string;
};

const Label = styled('label')<StyleProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  input + :before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.lightGrey};
    background: ${colors.snow};
  }

  input:checked + :before {
    background: radial-gradient(${props => props.color} 0%, ${props => props.color} 40%, transparent 50%, transparent);
    border-color: ${props => props.color};
  }

  input:checked {
    color: ${props => props.color};
  }
`;

const CarrierInfo = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 13px;
`;

const LogoImage = styled('img')`
  max-height: 30px;
  max-width: 30px;
  margin-right: 8px;
`;

type Props = {
  id: string;
  title: string;
  logoImageUrl?: string;
  checked?: boolean;
  onClick: (value: string) => void;
};

function CarrierOption(props: Props) {
  const { id, title, logoImageUrl, checked, onClick } = props;
  const { t } = useTranslation('carrier-services');

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onClick(e.target.value);
    },
    [onClick]
  );

  return (
    <Label key={id} color={colors.blue}>
      <input name="carrierSelect" type="radio" value={id} checked={checked} onChange={onChangeHandler} />
      <CarrierInfo>
        <LogoImage src={logoImageUrl} />
        <Text>{t(id, { defaultValue: title })}</Text>
      </CarrierInfo>
    </Label>
  );
}

export default React.memo(CarrierOption);
