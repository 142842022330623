import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { LocationState, OverviewLocationState } from '../../types';

import PageLayout from '../page-layout/PageLayout';
import ReturnConfirmationView from './ReturnConfirmationView';
import { scrollToTop } from '../domHelpers';
import useReturnRequest from '../../hooks/useReturnRequest';
import useRedirectTo from '../../hooks/useRedirectTo';
import ErrorPageWithLayout from '../error/ErrorPageWithLayout';
import useChangeLanguage from '../../hooks/useChangeLanguage';
import { getApiErrorCode } from '../../apiHelpers';

function ReturnConfirmationPage() {
  const location = useLocation<LocationState>();
  const { returnRequestId, returnState } = location.state || {};
  const [returnRequest, loading, error] = useReturnRequest(returnRequestId);
  const redirectToLogin = useRedirectTo('/login');
  const redirectToOverview = useRedirectTo<OverviewLocationState>('/return-overview', {
    isSubsequentReturnInitiated: true,
  });

  const { changeLanguage, selectedLanguage } = useChangeLanguage();

  if (!location.state) {
    redirectToLogin();
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  if (error) {
    return <ErrorPageWithLayout errorCode={getApiErrorCode(error)} />;
  }

  return (
    <PageLayout loading={loading} onLanguageChange={changeLanguage} selectedLanguage={selectedLanguage}>
      {returnRequest && (
        <ReturnConfirmationView
          returnRequest={returnRequest}
          returnState={returnState}
          onCreateSubsequentReturn={redirectToOverview}
        />
      )}
    </PageLayout>
  );
}

export default React.memo(ReturnConfirmationPage);
