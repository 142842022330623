import React from 'react';
import styled from '@emotion/styled';

type Props = {
  href?: string;
  onClick?: () => void;
  color: string;
  fontWeight?: string;
  children?: React.ReactNode | React.ReactNode[];
};

const StyledLink = styled('a')<Props>`
  color: ${props => props.color};
  text-decoration: none;
  font-weight: ${props => props.fontWeight || 'normal'};
  cursor: pointer; // needed when no "href"
`;

function Link(props: Props) {
  const { color, fontWeight, href, onClick, children } = props;

  return (
    <StyledLink
      color={color}
      fontWeight={fontWeight}
      href={href}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </StyledLink>
  );
}

export default React.memo(Link);
