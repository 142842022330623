import React from 'react';

import CarrierOption from './CarrierOption';
import Column from '../common/layout/Column';
import { Order, ReturnCarrierService } from '../../types';

type Props = {
  order?: Order;
  options?: ReturnCarrierService[];
  color?: string;
  selectedServiceId?: string;
  onClick: (value: string) => void;
};

function CarrierOptions(props: Props) {
  const { options = [], selectedServiceId, onClick } = props;

  return (
    <Column spacing={40} role="radiogroup">
      {options.map(({ id, carrierLogoUrl, title }) => (
        <CarrierOption
          key={id}
          id={id}
          logoImageUrl={carrierLogoUrl}
          title={title}
          checked={selectedServiceId === id}
          onClick={onClick}
        />
      ))}
    </Column>
  );
}

export default React.memo(CarrierOptions);
