import styled from '@emotion/styled';

const ProductItemOverlay = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

export default ProductItemOverlay;
